import React, { useState, useEffect } from "react";
import Layout from "./../../../components/Layout";
import Profil from "./../../../nav/Admin/Profil";
import { Helmet } from "react-helmet";
import { getUser } from "./../../../auth";
import Error403 from "../../../nav/Error403";
import { TailSpin } from "react-loader-spinner";

const HandleUserStates = ({ user, setUser }) => {
  if (user === undefined) {
    return (
      <div style={{ textAlign: "center" }}>
        <TailSpin color="#FF8000" height={200} width={200} />
      </div>
    );
  } else if (user && user.subscription.type === "admin") {
    return <Profil user={user} setUser={setUser} />;
  } else {
    return <Error403 />;
  }
};

const ProfilPage = () => {
  const [user, setUser] = useState(undefined);

  useEffect(() => {
    getUser(setUser);
  }, []);

  return (
    <Layout user={user}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>WYNIT | Profil</title>
        <meta
          name="description"
          content="WYNIT, les bons plans marseillais pour les 15-30 ans. Tu trouveras ici les informations concernant ton compte."
        />
        <meta name="keywords" content="réductions,jeunes,admin,profil" />
        <link rel="canonical" href="https://wynit.com/admin/profil" />
      </Helmet>
      <HandleUserStates user={user} setUser={setUser} />
    </Layout>
  );
};

export default ProfilPage;
