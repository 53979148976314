import React from "react"
import {
  Wrapper,
  Rounded,
  AdminWrapper,
  LineWrapper,
  InfosWrapper,
  RowWrapper,
  TitleInfo,
  Spacer,
  ChangePasswordWrapper,
  ButtonWrapper,
} from "./Profil.module.css"
import { OrangeTitle } from "./../../../css/Titre.module.css"
import Button from "./../../../components/Button"
import { classNames } from "./../../../helpers/classNames"
import { PaddingTop20 } from "./../../../css/Padding.module.css"
import { logOut } from "../../../auth"

const Profil = ({ user }) => {
  const handleLogOutButton = () => {
    logOut()
  }

  const getFormatedDate = () => {
    return Intl.DateTimeFormat("fr-FR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(user.birthDate / 1000)
  }

  const getTransformedPassword = () => {
    let idx = 0
    let result = ""

    while (idx < user.password.length) {
      result += "*"
      idx++
    }
    return result
  }

  return (
    <div className={Wrapper}>
      <img alt="profile" src={user.refImage} className={Rounded} />
      <h4 className={OrangeTitle}>{user.firstName + " " + user.lastName}</h4>
      <p className={AdminWrapper}>ADMIN</p>
      <div className={LineWrapper}></div>
      <div className={InfosWrapper}>
        <div className={classNames([RowWrapper, PaddingTop20])}>
          <p className={classNames([TitleInfo])}>Date de naissance</p>
          <div className={Spacer}></div>
          <p>{getFormatedDate()}</p>
        </div>
        <div className={classNames([RowWrapper, PaddingTop20])}>
          <p className={classNames([TitleInfo])}>Genre</p>
          <div className={Spacer}></div>
          <p>{user.gender}</p>
        </div>
        <div className={classNames([RowWrapper, PaddingTop20])}>
          <p className={classNames([TitleInfo])}>Code postal</p>
          <div className={Spacer}></div>
          <p>{user.postalCode}</p>
        </div>
        <div className={classNames([RowWrapper, PaddingTop20])}>
          <p className={classNames([TitleInfo])}>Ville</p>
          <div className={Spacer}></div>
          <p>{user.city}</p>
        </div>
        <div className={classNames([RowWrapper, PaddingTop20])}>
          <p className={classNames([TitleInfo])}>Numéro de téléphone</p>
          <div className={Spacer}></div>
          <p>{user.phoneNumber}</p>
        </div>
        <div className={classNames([RowWrapper, PaddingTop20])}>
          <p className={classNames([TitleInfo])}>Email</p>
          <div className={Spacer}></div>
          <p>{user.email}</p>
        </div>
        <div className={classNames([RowWrapper, PaddingTop20])}>
          <p className={classNames([TitleInfo])}>Mot de passe</p>
          <div className={Spacer}></div>
          <p>{getTransformedPassword()}</p>
        </div>
        <div className={PaddingTop20}>
          <a
            href="/admin/profil/modifier-mot-de-passe"
            className={ChangePasswordWrapper}
          >
            Modifier ton mot de passe
          </a>
        </div>
      </div>
      <div className={ButtonWrapper}>
        <Button alt="" onClick={() => handleLogOutButton()}>
          Se déconnecter
        </Button>
      </div>
    </div>
  )
}

export default Profil
